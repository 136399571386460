@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Montserrat:wght@500&family=Mouse+Memoirs&family=Oswald:wght@200&family=Pacifico&family=Poppins:wght@100&family=Roboto:wght@700&display=swap");

html {
  font-size: 62.5%;
}
body {
  margin: 0;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
  border-radius: 14.3393px;
  position: absolute;
}
/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.14);
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 14.3393px;
  background: rgba(0, 0, 0, 0.35);
}