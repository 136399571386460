.element {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    max-width: 10rem;
    margin: 1rem;

    max-height: 16.875rem;
    min-width: 25rem;

    :hover {
        cursor: pointer;
    }


    .imageContainer {
        border-radius: 0.3rem;
        overflow: hidden;


        aspect-ratio: 16/9;
        max-height: 16.875rem;
        min-width: 20rem;
        width: 100%;
        box-shadow: rgba(255, 255, 255, 0.144) 0px 0px 0px 1px;

        .imageDummy {
            min-width: 10rem;
            min-height: 6rem;
            height: 100%;
            color: black;
            background-color: #D9D9D9;
        }


        img {
            aspect-ratio: 1/1;
            width: 100%;
            height: 100%;
        }
    }
}