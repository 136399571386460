
/* font family */
@font-face {
    font-family: "OpenSans-Bold";
    src: url("/src/assets/fonts/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "OpenSans-Medium";
    src: url("/src/assets/fonts/OpenSans-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "OpenSans-Regular";
    src: url("/src/assets/fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "BebasNeue-Regular";
    src: url("/src/assets/fonts/BebasNeue-Regular.ttf") format("truetype");
}
  
@font-face {
    font-family: "CourierPrime-Bold";
    src: url("/src/assets/fonts/CourierPrime-Bold.ttf") format("truetype");
}
  
@font-face {
    font-family: "CourierPrime-Italic";
    src: url("/src/assets/fonts/CourierPrime-Italic.ttf") format("truetype");
}
  
@font-face {
    font-family: "CourierPrime-Regular";
    src: url("/src/assets/fonts/CourierPrime-Regular.ttf") format("truetype");
}
  
@font-face {
    font-family: "Figtree-Bold";
    src: url("/src/assets/fonts/Figtree-Bold.ttf") format("truetype");
}
  
@font-face {
    font-family: "Figtree-Medium";
    src: url("/src/assets/fonts/Figtree-Medium.ttf") format("truetype");
}
  
@font-face {
    font-family: "Figtree-MediumItalic";
    src: url("/src/assets/fonts/Figtree-MediumItalic.ttf") format("truetype");
}
  
@font-face {
    font-family: "IBMPlexMono-Bold";
    src: url("/src/assets/fonts/IBMPlexMono-Bold.ttf") format("truetype");
}
  
@font-face {
    font-family: "IBMPlexMono-Medium";
    src: url("/src/assets/fonts/IBMPlexMono-Medium.ttf") format("truetype");
}
  
@font-face {
    font-family: "IBMPlexMono-MediumItalic";
    src: url("/src/assets/fonts/IBMPlexMono-MediumItalic.ttf") format("truetype");
}
  
@font-face {
    font-family: "IBMPlexSerif-Bold";
    src: url("/src/assets/fonts/IBMPlexSerif-Bold.ttf") format("truetype");
}
  
@font-face {
    font-family: "IBMPlexSerif-Medium";
    src: url("/src/assets/fonts/IBMPlexSerif-Medium.ttf") format("truetype");
}
  
@font-face {
    font-family: "Lato-Bold";
    src: url("/src/assets/fonts/Lato-Bold.ttf") format("truetype");
}
  
@font-face {
    font-family: "Lato-Italic";
    src: url("/src/assets/fonts/Lato-Italic.ttf") format("truetype");
}
  
@font-face {
    font-family: "Lato-Regular";
    src: url("/src/assets/fonts/Lato-Regular.ttf") format("truetype");
}
  
@font-face {
    font-family: "Merriweather-Black";
    src: url("/src/assets/fonts/Merriweather-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Merriweather-Italic";
    src: url("/src/assets/fonts/Merriweather-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "Merriweather-Regular";
    src: url("/src/assets/fonts/Merriweather-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Bold";
    src: url("/src/assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Medium";
    src: url("/src/assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-MediumItalic";
    src: url("/src/assets/fonts/Montserrat-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Oswald-Bold";
    src: url("/src/assets/fonts/Oswald-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Oswald-Medium";
    src: url("/src/assets/fonts/Oswald-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Oswald-Regular";
    src: url("/src/assets/fonts/Oswald-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Bold";
    src: url("/src/assets/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Medium";
    src: url("/src/assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("/src/assets/fonts/Poppins-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Regular";
    src: url("/src/assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Bold";
    src: url("/src/assets/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-MediumItalic";
    src: url("/src/assets/fonts/Roboto-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Regularc";
    src: url("/src/assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Karla";
    src: url("/src/assets/fonts/Karla-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "SairaCondensed-Bold";
    src: url("/src/assets/fonts/SairaCondensed-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "SairaCondensed-Medium";
    src: url("/src/assets/fonts/SairaCondensed-Medium.ttf") format("truetype");
}
  
@font-face {
    font-family: "SairaCondensed-Regular";
    src: url("/src/assets/fonts/SairaCondensed-Regular.ttf") format("truetype");
}
  
@font-face {
    font-family: "Comfortaa-Bold";
    src: url("/src/assets/fonts/Comfortaa-Bold.ttf") format("truetype");
}
  
@font-face {
    font-family: "Comfortaa-Medium";
    src: url("/src/assets/fonts/Comfortaa-Medium.ttf") format("truetype");
}
  
@font-face {
    font-family: "Comfortaa-Regular";
    src: url("/src/assets/fonts/Comfortaa-Regular.ttf") format("truetype");
}
  
@font-face {
    font-family: "Exo-Black";
    src: url("/src/assets/fonts/Exo-Black.ttf") format("truetype");
}
  
@font-face {
    font-family: "Exo-Bold";
    src: url("/src/assets/fonts/Exo-Bold.ttf") format("truetype");
}
  
@font-face {
    font-family: "Exo-Medium";
    src: url("/src/assets/fonts/Exo-Medium.ttf") format("truetype");
}
  
@font-face {
    font-family: "Exo-Regular";
    src: url("/src/assets/fonts/Exo-Regular.ttf") format("truetype");
}
  
@font-face {
    font-family: "IBMPlexSans-Bold";
    src: url("/src/assets/fonts/IBMPlexSans-Bold.ttf") format("truetype");
}
  
@font-face {
    font-family: "IBMPlexSans-Medium";
    src: url("/src/assets/fonts/IBMPlexSans-Medium.ttf") format("truetype");
}
  
@font-face {
    font-family: "IBMPlexSans-Regular";
    src: url("/src/assets/fonts/IBMPlexSans-Regular.ttf") format("truetype");
}
  
@font-face {
    font-family: "Neucha-Regular";
    src: url("/src/assets/fonts/Neucha-Regular.ttf") format("truetype");
}
  
@font-face {
    font-family: "SpaceGrotesk-Bold";
    src: url("/src/assets/fonts/SpaceGrotesk-Bold.ttf") format("truetype");
}
  
@font-face {
    font-family: "SpaceGrotesk-Medium";
    src: url("/src/assets/fonts/SpaceGrotesk-Medium.ttf") format("truetype");
}
  
@font-face {
    font-family: "SpaceGrotesk-Light";
    src: url("/src/assets/fonts/SpaceGrotesk-Light.ttf") format("truetype");
}
  
@font-face {
    font-family: "BalooBhaijaan2-Bold";
    src: url("/src/assets/fonts/BalooBhaijaan2-Bold.ttf") format("truetype");
}
  
@font-face {
    font-family: "BalooBhaijaan2-Regular";
    src: url("/src/assets/fonts/BalooBhaijaan2-Regular.ttf") format("truetype");
}
  
@font-face {
    font-family: "BalooBhaijaan2-SemiBold";
    src: url("/src/assets/fonts/BalooBhaijaan2-SemiBold.ttf") format("truetype");
}
  
@font-face {
    font-family: "PassionOne-Black";
    src: url("/src/assets/fonts/PassionOne-Black.ttf") format("truetype");
}
  
@font-face {
    font-family: "PassionOne-Bold";
    src: url("/src/assets/fonts/PassionOne-Bold.ttf") format("truetype");
}
  
@font-face {
    font-family: "PassionOne-Regular";
    src: url("/src/assets/fonts/PassionOne-Regular.ttf") format("truetype");
}
  
@font-face {
    font-family: "RammettoOne-Regular";
    src: url("/src/assets/fonts/RammettoOne-Regular.ttf") format("truetype");
}
  
@font-face {
    font-family: "ChakraPetch-Bold";
    src: url("/src/assets/fonts/ChakraPetch-Bold.ttf") format("truetype");
}
  
@font-face {
    font-family: "ChakraPetch-Medium";
    src: url("/src/assets/fonts/ChakraPetch-Medium.ttf") format("truetype");
}
  
@font-face {
    font-family: "ChakraPetch-Regular";
    src: url("/src/assets/fonts/ChakraPetch-Regular.ttf") format("truetype");
}
  
@font-face {
    font-family: "ChakraPetch-SemiBold";
    src: url("/src/assets/fonts/ChakraPetch-SemiBold.ttf") format("truetype");
}